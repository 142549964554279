import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(!_vm.detail)?_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"600","color":"primary-light"}},[_c(VCardText,[_c(VCardTitle,[_vm._v(" Select Organization ")]),_c(VTextField,{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.searchOrganization),callback:function ($$v) {_vm.searchOrganization=$$v},expression:"searchOrganization"}}),(_vm.loadingOrganizations)?_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{staticClass:"mt-10 mb-10",attrs:{"size":"40","color":"primary","indeterminate":""}})],1):_c(VList,{attrs:{"color":"primary-light","nav":""}},[_c(VListItemGroup,_vm._l((_vm.sort({
                items: _vm.search({
                  items: _vm.organizationList,
                  query: _vm.searchOrganization,
                  fields: _vm.organizationSearchFields
                }),
                field: 'data.name'
              })),function(org){return _c(VListItem,{key:org.id,on:{"click":function($event){return _vm.selectOrg(org)}}},[_c(VListItemIcon,[_vm._v(" "+_vm._s(org.data.key)+" ")]),_c(VListItemContent,[_vm._v(" "+_vm._s(org.data.name)+" ")])],1)}),1)],1)],1)],1):_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('h1',{staticClass:"display-1"},[_vm._v("Team Assignment")]),_c('h1',{staticClass:"info--text title mt-2"},[_vm._v(" "+_vm._s(_vm.detail.data.name)+" "),_c(VBtn,{staticClass:"text-capitalize",attrs:{"small":"","color":"secondary","text":""},on:{"click":function($event){_vm.detail = null}}},[_vm._v(" Change Organization ")])],1)])]),_c(VDivider,{staticClass:"my-1"}),_c('ReviewTeamAssignmentTable',{attrs:{"id":_vm.detail.id,"organization":_vm.detail.data.key}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }