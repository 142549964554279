<template>
  <div>
    <div>
      <v-card class="mx-auto" max-width="600" color="primary-light" v-if="!detail">
        <v-card-text>
          <v-card-title> Select Organization </v-card-title>
          <v-text-field
            v-model="searchOrganization"
            label="Search"
            append-icon="mdi-magnify"
          ></v-text-field>
          <div v-if="loadingOrganizations" class="text-center">
            <v-progress-circular
              size="40"
              color="primary"
              class="mt-10 mb-10"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-list color="primary-light" nav v-else>
            <v-list-item-group>
              <v-list-item
                @click="selectOrg(org)"
                v-for="org in sort({
                  items: search({
                    items: organizationList,
                    query: searchOrganization,
                    fields: organizationSearchFields
                  }),
                  field: 'data.name'
                })"
                :key="org.id"
              >
                <v-list-item-icon> {{ org.data.key }} </v-list-item-icon>
                <v-list-item-content> {{ org.data.name }} </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>

      <div v-else>
        <div class="d-flex justify-space-between">
          <div>
            <h1 class="display-1">Team Assignment</h1>
            <h1 class="info--text title mt-2">
              {{ detail.data.name }}
              <v-btn small color="secondary" class="text-capitalize" text @click="detail = null">
                Change Organization
              </v-btn>
            </h1>
          </div>
        </div>
        <v-divider class="my-1"></v-divider>
        <ReviewTeamAssignmentTable :id="detail.id" :organization="detail.data.key" />
      </div>
    </div>
  </div>
</template>

<script>
import Organization from '../../services/organization';
import { search, sort } from '../../utils';
const org = new Organization();
export default {
  components: {
    ReviewTeamAssignmentTable: () => import('./ReviewTeamAssignmentTable.vue')
  },
  data() {
    return {
      organizationList: [],
      searchOrganization: '',
      organizationSearchFields: ['data.key', 'data.name'],
      loadingOrganizations: true,

      detail: null
    };
  },
  mounted() {
    this.getOrganizationList();
  },
  methods: {
    search,
    sort,
    selectOrg(org) {
      this.detail = org;
    },
    getOrganizationList() {
      const items = [];
      org
        .list()
        .then(res => {
          res.forEach(i => {
            items.push({ id: i.id, data: i.data() });
          });
          this.organizationList = items;
        })
        .finally(() => {
          this.loadingOrganizations = false;
        });
    }
  }
};
</script>

<style scoped>
.display-1 {
  color: rgb(77, 71, 71);
}
</style>
